.hero-image {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
  overflow: hidden;
}

.hero-image-two{
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: auto;
  top: 50%;     
  left: 50%;
  transform: translate( -50%, -50%);
  animation: fadein 3s;
  overflow: hidden;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.my-name{
  color: rgb(173, 92, 0);
  text-shadow: 0 0 1px rgb(58, 48, 45), 0 0 1px rgb(58, 48, 45);
  margin: auto auto !important;
}

.hero-text {
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(58, 48, 45);
  animation: fadein 3s;
}

ul {
  list-style-type: none;
}


h1 {
  font-weight: bolder !important;
  font-weight: bold;
  font-size: 4rem;
}
@media only screen and (max-width: 1184px) {
  h1{
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 1042px) {
  h1{
    font-size: 3rem;
  }
}

@media only screen and (max-width: 936px) {
  h1{
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 746px) {
  h1{
    font-size: 2.0rem;
  }
}


@media only screen and (max-width: 900px) {
  .hero-image-two{
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

