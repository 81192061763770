.project-image {
  position: relative;
  margin: 0 auto;
  background-color: white;
}

img {
  opacity: 1;
  width: 100%;
  height: auto;
  max-width: 900px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.project-container {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.project-image:hover img {
  opacity: 0.3;
}

.project-image:hover .project-container {
  opacity: 1;
}

.project-content {
  background-color: #5bc0de;
  padding: 16px 32px;
}

.btns {
  margin: 10px;
}
