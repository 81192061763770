.about-me{
  background-color: white;
  position:relative;
}

.desc-me {
  padding: 20px;
  margin: auto auto;
}

.about-me-title {
  padding: 15px;
}

h4 {
  padding: 15px;
}

p {
  font-size: 20px;
}

.img-me {
  margin: auto auto;
}

.bi {
  width: 42px;
  height: 42px;
  margin: 10px;
}
